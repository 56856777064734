@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './reports.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './scorecardWidgets.scss';

@import url('//fonts.googleapis.com/css?family=Jost:300,400,500,700,900&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap');

// @font-face {
//   font-family: 'Jost';
//   src: local('Jost'), url('../fonts/Jost-Regular.ttf') format('truetype');
// }

div,
span,
h1,
h2,
p,
ion-label {
  font-family: 'Jost' !important;
}

/* Backdrop styles */
.MuiBackdrop-root {
  background: unset;
  opacity: 0.7;
}

.multi-add-icon {
  height: 30px;
  cursor: pointer;
}

.multi-remove-icon {
  height: 30px;
  cursor: pointer;
}

/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiInputBase-root .MuiFormControl-root,
.MuiOutlinedInput-root {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  border-radius: 4px;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiInputBase-root {
  color: var(--input-text-color) !important;
  font-family: 'Jost';
  font-size: 14px;
  height: 48px;
  padding: 0;

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 16px;
    font-family: 'Jost';
    padding: 14px 16px;
  }

  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

.input-label {
  font-family: 'Jost';
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

// Modal styles
.modal-title {
  .MuiTypography-root {
    font-family: var(--headers-font-family) !important;
    font-size: 32px !important;
    color: #ffffff;
  }
}

.modal-content-ctn {
  background-color: #ffffff;
  background: #ffffff !important;

  .date-control,
  .input-control {
    border: 1px solid var(--filter-controls-border);
  }
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 40px !important;
}
.hand-pt {
  cursor: pointer;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
}

.MuiTab-root {
  text-transform: none;
}

.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  height: 100%;
  z-index: 1000
  // top: 5px;
}

.b-text {
  font-weight: 600;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
}

.profit {
  color: var(--ion-color-success);
}

.loss {
  color: var(--ion-color-danger);
}

// Carousel styles
.carousel .slide {
  background: none !important;
}

.game-rules-drawer {
  .MuiDrawer-paper {
    background-color: var(--ion-background-color) !important;
    min-width: 450px;
    @media only screen and (max-width: 400px) {
      min-width: 100%;
    }
  }

  &.mob-view {
    .MuiDrawer-paper {
      min-width: 100vw;
      .odds-terms-condi-ctn {
        padding: 10px 16px 0 16px;
        .MuiAccordion-root .MuiAccordionSummary-root {
          background: var(--table-header-background) !important;
          .MuiTypography-root {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.Toastify__toast-body {
  div {
    display: flex;
    .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }
}
.MuiAccordion-root::before {
  height: 0 !important;
}

@keyframes color-change {
  0% {
    color: red;
    font-weight: bolder;
  }

  50% {
    color: var(--ion-color-success);
    font-weight: 600;
  }

  100% {
    color: var(--ion-color-primary);
    font-weight: bolder;
  }
}

@keyframes cup-color-change {
    0% {
      color: pink;
    }
    25% {
      color: rgb(0, 255, 81);
    }
    50% {
      color: blue;
    }
    75% {
      color: red;
    }
    100% {
      color: rgb(225, 235, 234);
    }
  }


@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }

  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  75% {
    color: red;
    transform: scale(1);
  }

  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

//Disable step buttons of number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sc-ion-select-popover-ios {
  color: white !important;
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

ion-select-option {
  color: white !important;
}

.text-light {
  --color: var(--ion-text-light);
  --placeholder-color: var(--ion-text-light);
  color: var(--ion-text-light);
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  text-transform: capitalize;
  color: var(--ion-text-light);
  line-height: 21px;
}

/* Chrome, Safari, Edge, Opera */
input.sc-ion-input-md::-webkit-outer-spin-button,
input.sc-ion-input-md::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.password-dialog-ctn {
  &.MuiDialog-root
    .MuiDialog-container
    .MuiDialog-paper
    .MuiDialogContent-root {
    padding: 0 !important;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
